@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html{
        @apply  bg-gray-800
    }
}

a.active{
    @apply text-blue-400;
}

a.active hr{
    display: block;
    @apply bg-blue-400;
}

@media (max-width:640px) {
    a.active{
        @apply text-blue-400 font-semibold;
    }

    a.active hr{
        display: unset;
    }
}

@keyframes wave-hand {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-15deg);
    }
    75% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.animate-wave-hand {
    animation: wave-hand 1s ease-in-out infinite;
}
